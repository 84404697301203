/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
// import Download from '@pages/Download'
import OrderPay from '@pages/OrderPay'

function RouterView () {
    return useRoutes([
        {
            path: '/',
            element: <OrderPay />
        },
        // {
        //     path: '/download',
        //     element: <Download />
        // },
        {
            path: '/orderPay',
            element: <OrderPay />
        },
        {
            path: '*',
            element: <Navigate to="/" />
        }
    ])
}
export default RouterView
