import './App.css'
import { BrowserRouter as Router } from 'react-router-dom'
import React from 'react'
import RouterView from '@router/index'

export default function App () {
    return (
        <div className="App">
            <Router>
                <RouterView />
            </Router>
        </div>
    )
}
